import React from "react";
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Button, { ButtonProps } from "@material-ui/core/Button";

const {
  publicRuntimeConfig: { discordClientId },
} = getConfig();

const Login = (props: ButtonProps & { text?: string }) => {
  const baseUri = window.location.protocol + '//' + window.location.host;
  const redirectUri = `${baseUri}/api/discord/authorize`;
  const scopes = ['connections', 'guilds', 'identify', 'email'];
  const loginUri = `https://discord.com/api/oauth2/authorize?client_id=${discordClientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes.join(' ')}`;

  return (
    <Button href={loginUri} {...props}>
      {props.text || 'Entrar'}
    </Button>
  )
}

export default dynamic(() => Promise.resolve(Login), {
  ssr: false
})
